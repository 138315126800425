<template>
  <main class="h-screen overflow-hidden flex items-center justify-center px-5">
    <section class="thank-you-page">
      <div class="flex justify-center">
        <img src="@/assets/configuration/thankyou-subscribe.png" alt="" />
      </div>
      <h1 class="thank-you-page-heading">
        {{ $t("thank-you-page.heading-1") }}
        <span>{{ heading }}</span>
      </h1>

      <p class="thank-you-page-subheading">
        {{ subHeading }}
      </p>

      <div class="thank-you-page-button" @click="goToPage">
        {{ button }}
      </div>
    </section>
  </main>
</template>

<script>
import { inject, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

export default {
  setup() {
    const t = inject("t");
    const route = useRoute();
    const router = useRouter();
    const heading = ref("");
    const subHeading = ref("");
    const button = ref("");

    const goToPage = () => {
      if (route?.name === "ThankYou" || route?.name === "UnsuscribeSuccess") {
        router.push('/profile/profile-subscriptions');
      } else if (route?.name === "PaymentThankYou") {
        router.push('/profile/profile-payment');
      } 
    };

    onMounted(() => {
      if (route?.name === "ThankYou") {
        heading.value = t("thank-you-page.heading-subscribe");
        subHeading.value = t("thank-you-page.subheading-subscribe");
        button.value = t("thank-you-page.button-subscribe");
      } else if (route?.name === "UnsuscribeSuccess") {
        heading.value = t("thank-you-page.heading-unsubscribe");
        subHeading.value = t("thank-you-page.subheading-unsubscribe");
        button.value = t("thank-you-page.button-subscribe");
      } else if (route?.name === "PaymentThankYou") {
        heading.value = t("thank-you-page.heading-payment");
        subHeading.value = t("thank-you-page.subheading-payment");
        button.value = t("thank-you-page.button-payment");
      }
    });

    return {
      heading,
      subHeading,
      button,
      goToPage
    };
  },
};
</script>

<style lang="scss" scoped>
.thank-you-page {
  width: 66%;
  /* margin: 3vw auto 0; */
  background: #ffffff;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 3.5vw 9vw 4vw;
  max-height: 100vh;
  overflow-y: hidden;
}

.thank-you-page-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  margin: 3vw 0 1.5vw;
  line-height: 47px;
  color: #444444;

  span {
    color: #f48120;
  }
}

.thank-you-page-subheading {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  font-family: Popins;
  color: #636363;
}

.thank-you-page-button {
  padding: 10px 20px;
  background: #f48120;
  border-radius: 4px;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  cursor: pointer;
  display: inline-block;
  margin-top: 2rem;
  max-width: 200px;
}

@media (max-width: 768px) {
  .thank-you-page {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>